import { defineStore } from 'pinia';

export const useCommonStore = defineStore('common', {
  state: () => {
    return {
      isDark: false,
      isMobileSize: false,
      isAppSettingDialog: false,
      curProviderIndex: 0,
      currentChain: '',
      polling: '',
      currentToken: {
        addressRegExp: '',
        cryptoCoin: '',
        icon: '',
        chain: '',
      },
      cryptoGatewayData: {
        cryptCoin: '',
        currencyAmt: 0,
        currencyCoin: '',
        payType: '',
        tradeType: 'buy',
      },
      walletAddress: '',
      currencyCoinMaxAmt: '',
      currencyCoinMinAmt: ''
    };
  },
  actions: {
    changeMode(payload) {
      this.isDark = payload;
    },
    changeMobileSize(payload) {
      this.isMobileSize = payload;
    },
    changeAppSettingDialog(payload) {
      this.isAppSettingDialog = payload;
    },
  },
  getters: {
    // doubledCount: (state) => {
    //   return state.isDark;
    // },
  },
});
