<template>
  <div class="app-setting-other-list">
    <div class="other-header">
      <img :src="backImg" alt="" @click.stop="onBack" />
      <div class="other-text">{{ settingTitle }}</div>
    </div>
    <div class="other-list">
      <div
        class="setting-item bg-color-overlay"
        v-for="(settingItem, index) in list"
        :key="index"
        @click.stop="onSelect(settingItem)"
      >
        <div class="setting-left">
          <img
            v-if="
              settingItem.iconSelect &&
              settingItem.theme &&
              settingItem.theme === theme
            "
            :src="settingItem.iconSelect"
            alt=""
          />
          <img v-else-if="settingItem.icon" :src="settingItem.icon" alt="" />
          <span>{{ settingItem.name }}</span>
        </div>
        <img
          class="select"
          v-if="settingItem.theme && settingItem.theme === theme"
          :src="selectImg"
          alt=""
        />
        <!-- v-if="curTheme(settingItem)" -->
        <img
          class="select"
          v-if="settingItem.language && settingItem.language === $i18n.locale"
          :src="selectImg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import { useCommonStore } from '../../stores/common';
const common = useCommonStore();

const props = defineProps({
  settingTitle: String,
  list: Array,
  theme: String,
});

// console.log('settingTitle', props.settingTitle);

const emit = defineEmits(['goBack', 'getSetting']);

const curSettingIndex = ref(0);

const darkModeImg = computed(() => {
  return common.isDark ? '-dark' : '';
});

const backImg = computed(() => {
  return require(`../../assets/header/back${darkModeImg.value}.png`);
});

const selectImg = computed(() => {
  return require(`../../assets/header/select${darkModeImg.value}.png`);
});

const curTheme = (settingItem) => {
  if (settingItem.theme === 'auto') {
    return true;
  } else {
    if (settingItem.theme === props.theme) {
      return true;
    } else {
      return false;
    }
  }
};

const onBack = () => {
  emit('goBack');
};

const onSelect = (item) => {
  emit('getSetting', item);
};
// export default {
//   props: {
//     settingTitle: {
//       type: String,
//     },
//     list: {
//       type: Array,
//     },
//     theme: {
//       type: String,
//     },
//   },
//   data() {
//     return {
//       curSettingIndex: 0,
//     };
//   },

//   computed: {
//      modeImgStr() {
//       let str = '';
//       if (this.isDarkMode) {
//         str = '-dark';
//       }
//       return str;
//     },
//     backImg() {
//       return require(`../../assets/header/back${this.modeImgStr}.png`)
//     },
//     selectImg() {
//       return require(`../../assets/header/select${this.modeImgStr}.png`)
//     },
//   },

//   methods: {
//     curTheme(settingItem) {
//       if (settingItem.theme === 'auto') {
//         return true
//       } else {
//         if (settingItem.theme === this.theme) {
//           return true
//         } else {
//           return false
//         }
//       }
//     },
//     onBack() {
//       this.$emit('goBack');
//     },
//     onSelect(item) {
//       // console.log('item', item);
//       this.$emit('getSetting', item);
//     },
//   },
// };
</script>

<style lang="scss" scoped>
.app-setting-other-list {
  .other-header {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    .other-text {
      font-size: 15px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: var(--color);
      line-height: 21px;
    }
  }
  .other-list {
    margin-top: 10px;
    .setting-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 4px;
      border-radius: 12px;
      cursor: pointer;
      .setting-left {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        span {
          margin-left: 8px;
          font-size: 13px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: var(--color);
          line-height: 18px;
        }
      }
      .select {
        width: 16px;
        height: 16px;
      }
    }
    .setting-item:hover {
      &::before {
        background: var(--bg-1);
        border-radius: 12px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .app-setting-other-list {
    .other-header {
      .other-text {
        font-size: 16px;
      }
    }
    .other-list {
      .setting-item {
        padding: 20px 4px;
        .setting-left {
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
}
</style>
