import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import router from './router';
import i18n from './i18n';
import './style/index.scss';
import './assets/fonts/selfFont.css';

createApp(App).use(createPinia()).use(i18n).use(router).mount('#app');

