import { BigNumber } from 'bignumber.js';

export function getSystemTheme() {
  const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)'); // 是深色
  // console.log('isDarkTheme', isDarkTheme);
  if (isDarkTheme.matches) {
    // 是深色
    return 'dark';
  } else {
    // 不是深色
    return 'light';
  }
}

/** 设置显示模式 */
export function setMode(mode) {
  if (mode) {
    document.documentElement.setAttribute('theme', 'dark');
  } else {
    document.documentElement.removeAttribute('theme');
  }
}

export async function getIP() {
  // return fetch('https://ipapi.co/json/')
  //   .then((response) => response.json())
  //   .then((data) => data.ip)
  //   .catch((error) => console.error(error));
  try {
    const response = await fetch('https://ipapi.co/json/');
    const res = await response.json();
    console.log('getIP', res);
    return res.ip;
  } catch (error) {
    // console.error('Error fetching IP address:', error);
    return getIPByIPIfy();
  }
}

export async function getIPByIPIfy() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const res = await response.json();
    console.log('getIPByIPIfy', res);
    return res.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
  }
}

export function getLocalIPs() {
  // debugger
  const ips = [];
  const RTCPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  if (!RTCPeerConnection) {
    console.error('WebRTC is not supported by this browser');
    return;
  }

  const rtc = new RTCPeerConnection({ iceServers: [] });
  rtc.createDataChannel('');
  rtc
    .createOffer()
    .then((offer) => rtc.setLocalDescription(offer))
    .catch((error) => console.error(error));
  console.log('rtc', rtc);

  rtc.onicecandidate = (ice) => {
    console.log('ice', ice);
    if (!ice || !ice.candidate || !ice.candidate.candidate) return;
    const candidate = ice.candidate.candidate;
    const regex = /([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})/;
    const match = candidate.match(regex);
    if (match && !ips.includes(match[1])) {
      ips.push(match[1]);
      console.log('ips', ips);
      // this.ips = ips;
      return ips;
    }
  };
}

/** 加法 */
export function add(a, b) {
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.plus(b).toNumber()); //结果需要用toNumber转为普通数字
}

/** 减法 */
export function minus(a, b) {
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.minus(b).toNumber());
}

/** 乘法 */
export function mut(a, b) {
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.multipliedBy(b).toNumber());
}

/** 除法 */
export function dev(a, b) {
  // console.log('a', a);
  // console.log('b', b);
  a = BigNumber(a);
  b = BigNumber(b);
  return toNonExponential(a.dividedBy(b).toNumber());
}

/** 科学计数法转数字 */
export function toNonExponential(num) {
  // console.log('num', num);
  try {
    var m = num.toExponential().match(/\d(?:.(\d*))?e([+-]\d+)/);
    return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
  } catch (error) {
    console.log(error);
  }
}

/** 找出数组中的最大最小值的对象 */
export function findMinMaxAmounts(arr) {
  // 过滤出 currencyCoinMinAmt 和 currencyCoinMaxAmt 都存在的对象
  const filteredArr = arr.filter(
    (item) => item.currencyCoinMinAmt && item.currencyCoinMaxAmt
  );

  // 找到 currencyCoinMinAmt 最小的对象
  const minAmountObj = filteredArr.reduce(
    (min, curr) =>
      parseFloat(curr.currencyCoinMinAmt) < parseFloat(min.currencyCoinMinAmt)
        ? curr
        : min,
    filteredArr[0]
  );

  // 找到 currencyCoinMaxAmt 最大的对象
  const maxAmountObj = filteredArr.reduce(
    (max, curr) =>
      parseFloat(curr.currencyCoinMaxAmt) > parseFloat(max.currencyCoinMaxAmt)
        ? curr
        : max,
    filteredArr[0]
  );

  return [minAmountObj, maxAmountObj];
}

export function parseWithUnit(val) {
  try {
    if (val >= 1000000000000) {
      return Math.round(val / 1000000000) / 1000 + 'T';
    } else if (val < 1000000000000 && val >= 1000000000) {
      return (val / 1000000000).toFixed(1) + 'B';
    } else if (val < 1000000000 && val >= 1000000) {
      return (val / 1000000).toFixed(1) + 'M';
    } else if (val < 1000000 && val >= 100000) {
      return Math.round(val / 10) / 100 + 'K';
    } else if (val < 100000 && val >= 1) {
      // return val;
      return val.toLocaleString();
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err, val);
  }
}

export function parseData(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log(e);
  }
}

export function sortByFirstLetter(array, propertyName) {
  // 使用Array.sort()方法对数组进行排序
  array.sort(function (a, b) {
    // 将name属性的值转换为小写以确保不区分大小写
    const nameA = a[propertyName].toLowerCase();
    const nameB = b[propertyName].toLowerCase();
    // 比较首字母
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // 如果首字母相同，则按原始顺序排序
    return 0;
  });
  return array;
}
