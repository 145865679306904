export default {
  feedbackTitle: 'Transit Buy',
  swap: 'Swap&Bridge',
  markets: 'Markets',
  comingSoon: 'Coming soon',
  startNow: 'Start now',

  header: {
    buyToken: 'Buy Crypto',
    searchTokens: 'Search tokens',
    recentSearches: 'Recent Searches',
    popularTokens: 'Popular Tokens',
    noSearchContent: 'No tokens found.',
    markets: 'Market',
    inscribeTool: 'Inscribe Tool',
  },

  appSetting: {
    theme: 'Theme',
    light: 'Light',
    dark: 'Dark',
    auto: 'Auto',

    language: 'Language',
    langText: 'English',

    showChart: 'Show Chart',
    contactUs: 'Contact Us',
  },

  home: {
    modal_contact_us: {
      title: 'Contact Us',
      text_1:
        "* Please be aware that Transit will never contact you first, we won't ask you to charge any fee either.",
      text_2: 'Contact Transit Buy',
    },
    text_1_1: 'Buy ',
    text_1_2: 'cryptocurrency',
    text_1_3: 'easier with',
    text_1_4: 'Transit Buy',
    text_2: 'Cryptocurrencies',
    text_3: 'Countries',
    text_4: 'Our partners',
    text_5:
      'Buy and sell your cryptocurrencies with trusted partners and payment methods',
    text_6: 'More options to choose, better experience to get.',
    text_7: 'Make crypto easier & more convenient for you',
    text_8:
      'You can buy and sell 100+ cryptocurrencies with 150+ fiat currencies using your favorite payment methods.',
    text_9: 'Best prices',
    text_10:
      'We will help you to query the price from our partners and show as the list. You can choose from best price for yourself, and also the supported payment methods.',
    text_11: 'Privacy protection',
    text_12:
      'Throughout the entire buy or sell process, all personal privacy, including identity information and payment details, is protected and not disclosed.',
    text_13: 'Truly owned',
    text_14:
      'After purchasing cryptocurrencies, it will directly send to your own wallet address, enabling unrestricted and free transfer of your cryptocurrencies. You truly own it!',
    text_15: 'Start your crypto journey now',
    text_16: 'Buy and sell 100+ cryptocurrencies with 150+ fiat currencies ',
    card_text_1: 'Choose your favorite payment methods',
    card_text_2: 'Backed by world-leading on-ramp partners',
    card_text_3:
      'More than 100 countries and regions are covered around the world.',
    card_text_4:
      'USD, EUR, HKD, SGD, GBP and 150+ other fiat currencies are supported.',
    card_text_5:
      'USDT, BTC, ETH, TRX and 100+ other cryptocurrencies are listed.',
  },

  ramp: {
    buy: 'Buy',
    sell: 'Sell',
    selectCurrency: 'Select a Currency',
    toBuy: 'To Buy',
    payWith: 'Pay With',
    selectToken: 'Select token',
    placeholder: 'Search token',
    placeholder1: 'Search for a fiat currency',
    popular: 'Popular',
    all: 'All',
    creditCard: 'Payment Method',
    providerTitle: 'Select a Provider',
    providerDesc: 'Choose the provider to serve you best!',
    providerDesc_1: 'You are converting {currencyAmt} {currencyCoin} to {cryptCoin}, choose the provider to serve you best!',
    providers: 'Providers',
    noProviders: 'No service provider found~',
    estimated: 'Estimated To Get',
    receiveAddress: 'Receive Address',
    confirm: 'Confirm',
    next: 'Next',
    validAddress: 'validAddress',
    limitAmount: 'Limit Amount',
    quotesStopped: 'Oops, quotes stopped!',
    restart: 'Restart',
    addressRequired: 'Wallet address is required',
    paymentRequired: 'Payment method is required',
    emptyText: 'No corresponding payment methods',
    orderFailed: 'Order failed, please try again',
    googlePayText_1: 'Your selected payment method is Google Pay. Please follow the steps below to proceed.',
    googlePayText_2: 'We have copied the redirect URL for you.',
    googlePayText_3: 'Open system browser, paste the URL and visit the website.',
  },

  footer: {
    Features: {
      title: 'Features',
      swap: 'Swap&Bridge',
      bridge: 'Bridge',
      market: 'Market',
      buyCrypto: 'Buy Crypto',
      explorer: 'Explorer',
    },
    NFT: {
      title: 'NFT',
      design: 'Design',
      transitNFT: 'Transit NFT',
      marketplace: 'Marketplace',
    },
    Governance: {
      title: 'Governance',
      dao: 'DAO',
      donation: 'Donation',
      fee: 'Fee',
    },
    Community: {
      title: 'Community',
      developers: 'Developers',
      forum: 'Forum',
      twitter: 'Twitter',
      downloadTP: 'Download TP',
    },
    Help: {
      title: 'Get Help',
      contactUs: 'Contact Us',
      helpCenter: 'Help Center',
    },
    Links: {
      title: 'Links',
      TP: 'TokenPocket',
      Trading_View: 'Trading View',
      Terms: 'Terms & Conditions',
      Privacy_Policy: 'Privacy Policy',
      bugBounty: 'Bug Bounty',
    },
  },
};
