export default {
  feedbackTitle: 'Transit Buy',
  swap: '交易&跨链',
  markets: '市场',
  comingSoon: '即将推出',
  startNow: '前往购买',

  header: {
    buyToken: '购买代币',
    searchTokens: '搜索代币',
    recentSearches: '最近搜索',
    popularTokens: '热门代币',
    noSearchContent: '暂无搜索内容',
    markets: '市场',
    inscribeTool: '铭刻工具',
  },

  appSetting: {
    theme: '主题',
    light: '浅色主题',
    dark: '深色主题',
    auto: '跟随系统',

    language: '语言',
    langText: '中文',

    showChart: '市场行情',
    contactUs: '联系我们',
  },

  home: {
    modal_contact_us: {
      title: '联系方式',
      text_1:
        '* 请注意，Transit团队绝不会主动联系您，我们也不会要求您支付任何费用。',
      text_2: '联系 Transit Buy',
    },
    text_1_1: '通过 ',
    text_1_2: 'Transit Buy',
    text_1_3: '更轻松地购买',
    text_1_4: '加密货币',
    text_2: '加密货币',
    text_3: '国家',
    text_4: '合作伙伴',
    text_5: '使用可信赖的合作伙伴和支付方式购买和出售您的加密货币',
    text_6: '更多的选择，更好的体验',
    text_7: '让加密货币变得更简单、更便捷',
    text_8:
      '您可以使用您喜爱的支付方式，以150多种法币购买和出售100多种加密货币',
    text_9: '最优价格',
    text_10:
      '我们将帮助您从我们的合作伙伴那里查询价格，并将其显示在列表中。您可以为自己选择最优惠的价格，同时还可以选择支持的支付方式。',
    text_11: '隐私保护',
    text_12:
      '在整个购买或出售过程中，所有个人隐私，包括身份信息和支付详情，都会得到保护，并且不会被泄露。',
    text_13: '真正拥有',
    text_14:
      '购买加密货币后，它将直接发送到您自己的钱包地址，使您的加密货币能够自由转移和无限制地进行交易。您真正拥有它！',
    text_15: '开启您的加密货币之旅吧！',
    text_16: '使用150多种法定货币购买和出售100多种加密货币',
    card_text_1: '选择您喜爱的支付方式',
    card_text_2: '由世界领先的合作伙伴支持',
    card_text_3: '覆盖全球100多个国家和地区',
    card_text_4: '支持美元、欧元、港币、新加坡元、英镑等150多种其他法定货币',
    card_text_5: '支持USDT、BTC、ETH、TRX等100多种其他加密货币',
  },

  ramp: {
    buy: '购买',
    sell: '出售',
    selectCurrency: '选择支付货币',
    toBuy: '购买币种',
    payWith: '支付方式',
    selectToken: '选择购买币种',
    placeholder: '请输入您要购买的加密货币符号',
    placeholder1: '搜索法定货币',
    popular: '热门币种',
    all: '全部',
    creditCard: '选择支付方式',
    providerTitle: '选择服务商',
    providerDesc: '请选择符合您需求的服务商进行购买！',
    providerDesc_1: '您正在将 {currencyAmt} {currencyCoin} 兑换为 {cryptCoin}，请选择为您提供最佳服务的服务商！',
    providers: '服务商',
    noProviders: '没匹配到服务商～',
    estimated: '预计获得',
    receiveAddress: '接收地址',
    confirm: '确定',
    next: '下一步',
    validAddress: '地址无效，请检查后重试',
    limitAmount: '限制金额',
    quotesStopped: '糟糕，报价已停止!',
    restart: '重试一次',
    addressRequired: '钱包地址为必填项',
    paymentRequired: '支付方式为必填项',
    emptyText: '没有相对应的支付方式～',
    orderFailed: '下单失败，请重试',
    googlePayText_1: '您选择的支付方式为 Google Pay，需要进行以下方式进行下一步操作',
    googlePayText_2: '已为您复制跳转链接。',
    googlePayText_3: '请您打开系统浏览器，粘贴并访问该页面。',
  },

  footer: {
    Features: {
      title: '功能',
      swap: '闪兑&跨链',
      bridge: '跨链桥',
      market: '市场行情',
      buyCrypto: '购买',
      explorer: '浏览器',
    },
    NFT: {
      title: 'NFT',
      design: '设计大赛',
      transitNFT: 'Transit NFT',
      marketplace: 'NFT市场',
    },
    Governance: {
      title: '治理',
      dao: 'DAO',
      donation: '捐赠',
      fee: '费率',
    },
    Community: {
      title: '社区',
      developers: '开发者',
      forum: '论坛',
      twitter: 'Twitter',
      downloadTP: '下载 TP',
    },
    Help: {
      title: '帮助',
      contactUs: '联系我们',
      helpCenter: '帮助中心',
    },
    Links: {
      title: '链接',
      TP: 'TokenPocket',
      Trading_View: 'Trading View',
      Terms: '服务条款',
      Privacy_Policy: '隐私政策',
      bugBounty: '漏洞赏金',
    },
  },
};
