<template>
  <div
    class="footer-container"
    :class="{ security: security, isKeyPal: isKeyPal }"
  >
    <div class="content">
      <div class="follow-wrap left">
        <img class="logo" :src="logoImg" @click="goHome" />
        <div>
          <div class="footer-follows">
            <a
              class="follow-item"
              :class="[item.class]"
              :href="item.url"
              target="_blank"
              v-for="(item, index) in followList"
              :key="index"
            >
              <!-- @click="openFollow(item)" -->
              <div class="footer-follow"></div>
            </a>
          </div>
          <div class="copyright" :class="{ security: security }">
            ©2023 Transit Finance
          </div>
        </div>
      </div>
      <div class="footer-wrap">
        <div
          v-for="(item, index) in footerList"
          :key="index"
          class="footer-list"
        >
          <div class="title" :class="{ security: security }">
            {{ item.title }}
          </div>

          <div class="footer-link-wrap">
            <div
              class="footer-link"
              v-for="(data, dataIndex) in item.data"
              :key="dataIndex"
            >
              <a
                class="pointer"
                :class="{ security: security }"
                :href="data.url ? data.url : 'javascript:void(0)'"
                :target="data.local ? '_self' : '_blank'"
                @click.stop="onFooterLink(data)"
                >{{ data.title }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import { useCommonStore } from '../../stores/common';
const common = useCommonStore();

import { useI18n } from 'vue-i18n';
const { locale, t } = useI18n();

import { useRouter } from 'vue-router';
const router = useRouter();

import { EXPLORER_TRANSIT_DOMAIN } from '../../config';
import emitter from '../../utils/bus';

const props = defineProps({
  security: Boolean,
  isKeyPal: Boolean,
});

const explorer = ref(EXPLORER_TRANSIT_DOMAIN);

const darkModeImg = computed(() => {
  return common.isDark ? '-dark' : '';
});

const logoImg = computed(() => {
  return require(`../../assets/home/Transit${darkModeImg.value}.png`);
});

const inscribeToolUrl = computed(() => {
  return `https://tp-lab.tokenpocket.pro/insc/?locale=${locale.value}#/`;
});

const followList = computed(() => {
  return [
    {
      url: 'https://twitter.com/TransitFinance',
      class: `follow-item-1${darkModeImg.value}`,
    },
    {
      url: 'https://t.me/Transit_Finance',
      class: `follow-item-2${darkModeImg.value}`,
    },
    {
      url: 'mailto:service@transit.finance',
      class: `follow-item-3${darkModeImg.value}`,
    },
    {
      url: 'https://github.com/transit-finance',
      class: `follow-item-4${darkModeImg.value}`,
    },
    {
      url: 'https://fans.tokenpocket.pro/c/transit-swap-announcement-transit-swap/14',
      class: `follow-item-5${darkModeImg.value}`,
    },
  ];
});

const footerList = computed(() => {
  return [
    {
      title: t('footer.Features.title'),
      data: [
        {
          title: t('footer.Features.swap'),
          url: `https://swap.transit.finance/?locale=${locale.value}#/`,
        },
        {
          title: t('footer.Features.market'),
          url: `https://swap.transit.finance/?locale=${locale.value}#/market`,
          // comingSoon: true,
        },
        {
          title: t('footer.Features.buyCrypto'),
          local: true,
          routePath: '/ramp'
        },
        {
          title: t('footer.Features.explorer'),
          url: explorer.value.replace('hash/', ''),
        },
        {
          title: t('header.inscribeTool'),
          url: inscribeToolUrl.value,
        },
      ],
    },
    {
      title: t('footer.NFT.title'),
      data: [
        {
          title: t('footer.NFT.design'),
          url: 'https://tp-lab.tokenpocket.pro/mint-skin-2/index.html#/',
        },
        {
          title: t('footer.NFT.transitNFT'),
          url: 'https://nft.transit.finance/#/',
        },
        {
          title: t('footer.NFT.marketplace'),
          url: 'https://nft.transit.finance/waitlist/#/',
        },
      ],
    },
    {
      title: t('footer.Governance.title'),
      data: [
        {
          title: t('footer.Governance.dao'),
          url: 'https://www.transit.finance/#/stake',
        },
        {
          title: t('footer.Governance.donation'),
          url: 'https://www.transit.finance/#/donation',
        },
        {
          title: t('footer.Governance.fee'),
          url: 'https://www.transit.finance/#/fee',
        },
      ],
    },
    {
      title: t('footer.Community.title'),
      data: [
        {
          title: t('footer.Community.developers'),
          url:
            locale.value === 'zh'
              ? 'https://docs.transit.finance/cn/'
              : 'https://docs.transit.finance/en/',
        },
        {
          title: t('footer.Community.forum'),
          url: 'https://fans.tokenpocket.pro/c/transit-swap-announcement-transit-swap/14',
        },
        {
          title: t('footer.Community.twitter'),
          url: 'https://twitter.com/TransitFinance',
        },
        // {
        //   title: t('footer.Community.downloadTP'),
        //   url:
        //     locale.value === 'zh'
        //       ? 'https://tp.transit.finance/zh/download/app'
        //       : 'https://tp.transit.finance/en/download/app',
        // },
      ],
    },
    {
      title: t('footer.Help.title'),
      data: [
        {
          title: t('footer.Help.contactUs'),
          contactUs: true,
          local: true,
        },
        {
          title: t('footer.Help.helpCenter'),
          url:
            locale.value === 'zh'
              ? 'https://docs.transit.finance/cn/'
              : 'https://docs.transit.finance/en/',
        },
      ],
    },
    {
      title: t('footer.Links.title'),
      data: [
        {
          title: t('footer.Links.TP'),
          url: 'https://www.tpwallet.io/',
        },
        {
          title: t('footer.Links.Trading_View'),
          url: 'https://tradingview.com/',
        },
        {
          title: t('footer.Links.Terms'),
          url: 'https://www.transit.finance/#/terms'
        },
        {
          title: t('footer.Links.Privacy_Policy'),
          url: 'https://www.transit.finance/#/privacy-policy'
        },
        {
          title: t('footer.Links.bugBounty'),
          url: `https://bugrap.io/bounties/Transit%20swap`,
        },
      ],
    },
  ];
});

const openFollow = (item) => {
  if (item.isTelegram) {
    this.url = item.url;
  } else {
    window.open(item.url);
  }
};

const onFooterLink = (data) => {
  if (data.lang) {
    locale.value = data.lang;
    document.title = this.$t('common.documentTitle');
  } else if (data.routePath) {
    router.push({ path: data.routePath });
  } else if (data.contactUs) {
    emitter.emit('contact-us', { show: true });
  } else if (data.comingSoon) {
    console.log('comingSoon');
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  background: var(--footer-bg);
  padding: 0.68rem 0 0.78rem;

  @for $i from 1 through 5 {
    .follow-item-#{$i} {
      border: 0.01rem solid var(--border-1);
      .footer-follow {
        background: url('../../assets/footer/#{$i}.png')
          no-repeat
          100% /
          contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 0.01rem solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/#{$i}-on.png')
            no-repeat
            100% /
            contain;
        }
      }
    }
    .follow-item-#{$i}-dark {
      border: 0.01rem solid var(--border-1);
      .footer-follow {
        background: url('../../assets/footer/#{$i}-dark.png')
          no-repeat
          100% /
          contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 0.01rem solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/#{$i}-on.png')
            no-repeat
            100% /
            contain;
        }
      }
    }
  }

  .content {
    max-width: 13.6rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      font-size: 0;
      display: flex;
      flex-direction: column;
    }
    .follow-wrap {
      display: flex;
      .logo {
        width: 1.28rem;
        cursor: pointer;
      }
      .footer-follows {
        display: flex;
        margin: 0.32rem 0 0.24rem;
        .follow-item {
          width: 0.44rem;
          height: 0.44rem;
          // border: 1px solid var(--border-1);
          border-radius: 0.12rem;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(n + 2) {
            margin-left: 0.12rem;
          }
          .footer-follow {
            width: 0.24rem;
            height: 0.24rem;
          }
        }
      }
      .copyright {
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC-Regular;
        color: var(--color);
      }
    }
    .footer-wrap {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 3.13rem;
      .footer-list {
        padding: 0 0.1rem;
        .title {
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: var(--color);
          line-height: 0.22rem;
        }
        .footer-link-wrap {
          margin-top: 0.2rem;
          :nth-child(n + 2) {
            margin-top: 0.2rem;
          }
          .footer-link {
            a {
              display: flex;
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC-Medium;
              // font-weight: 500;
              font-weight: 400;
              color: var(--color-4);
              line-height: 0.2rem;
            }
            a:hover {
              color: var(--color);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1450px) {
  .footer-container {
    .content {
      padding: 0 0.3rem;
    }
  }
}
@media screen and (max-width: 1200px) {
  .footer-container {
    .content {
      flex-direction: column;
      .footer-wrap {
        margin-top: 0.5rem;
        margin-left: 0;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .footer-container {
    @for $i from 1 through 5 {
      .follow-item-#{$i} {
        border: 1px solid var(--border-1);
        .footer-follow {
          background: url('../../assets/footer/#{$i}.png')
            no-repeat
            100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          border: 1px solid var(--border-1);
          .footer-follow {
            background: url('../../assets/footer/#{$i}.png')
              no-repeat
              100% /
              contain;
          }
        }
      }
      .follow-item-#{$i}-dark {
        .footer-follow {
          background: url('../../assets/footer/#{$i}-dark.png')
            no-repeat
            100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          // border: 1px solid #2980fe;
          .footer-follow {
            background: url('../../assets/footer/#{$i}.png')
              no-repeat
              100% /
              contain;
          }
        }
      }
    }

    .content {
      .follow-wrap {
        // align-items: center;
        .logo {
          width: 115px;
        }
        .footer-follows {
          margin: 22px 0 16px;
          .follow-item {
            width: 44px;
            height: 44px;
            border-radius: 12px;
            .footer-follow {
              width: 24px;
              height: 24px;
              &:nth-child(n + 2) {
                margin-left: 20px;
              }
            }
          }
        }
        .copyright {
          font-size: 12px;
        }
      }
      .footer-wrap {
        margin-top: 30px;
        .footer-list {
          width: 45%;
          padding: 0;
          &:nth-child(n + 3) {
            margin-top: 30px;
          }
          .title {
            padding: 0;
            text-align: left;
            font-size: 14px;line-height: 22px;
          }
          .footer-link-wrap {
            margin-top: 14px;
            .footer-link {
              &:nth-child(n + 2) {
                margin-top: 15px;
              }
              a {
                font-size: 13px;
                line-height: 20px;
              }
              .security:hover {
                color: rgba(255, 255, 255, 0.4);
              }
              a:hover {
                color: rgba(16, 16, 16, 0.4);
              }
            }
          }
        }
      }
    }
  }
}
</style>
