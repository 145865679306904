<template>
  <div class="home">
    <Header />
    <div class="home-container">
      <div class="introduction-content">
        <div class="introduction">
          <div class="title-wrap">
            <div class="title">
              <span class="Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">{{
                $t('home.text_1_1')
              }}</span>
              <span class="Inter-Bold">{{ $t('home.text_1_2') }}</span>
            </div>
            <div class="title Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">
              {{ $t('home.text_1_3') }}
            </div>
            <div class="title Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">
              {{ $t('home.text_1_4') }}
            </div>
          </div>
          <div
            class="button Inter-Bold"
            :class="{ 'Alibaba-Bold': isZh }"
            @click="goRamp"
            >{{ $t('startNow') }}</div
          >
          <div class="introduction-list" :class="{ chinese: isZh }">
            <div
              class="introduction-item"
              v-for="(item, index) in introductionList"
              :key="index"
            >
              <img :src="item.icon" alt="" />
              <div class="text-wrap">
                <span class="Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">{{
                  item.text_1
                }}</span>
                <span
                  class="Inter-Medium"
                  :class="{ 'Alibaba-Medium': isZh }"
                  >{{ item.text_2 }}</span
                >
              </div>
            </div>
          </div>
          <!-- <img class="main-img-pc" :src="mainImgPc" alt="" /> -->
          <img class="main-img-pc" v-if="common.isDark" src="../assets/home/main-img-pc-dark.png" alt="" />
          <img class="main-img-pc" v-else src="../assets/home/main-img-pc.png" alt="" />
          <img class="main-img-mobile" :src="mainImg" alt="" />
        </div>
        <div class="partners-content">
          <div class="partners-wrap">
            <div class="title Inter-SemiBold" :class="{ 'Alibaba-Bold': isZh }">
              {{ $t('home.text_4') }}
            </div>
            <div
              class="partners-list"
              ref="partnersNode"
              :style="{ transform: `translateX(${offset}px)` }"
            >
              <div
                class="partners-item"
                v-for="(item, index) in partnerList2"
                :key="index"
              >
                <img :src="item.logo" alt="" />
              </div>
            </div>
            <div class="shadow"></div>
          </div>
        </div>
        <!-- <div class="bg-over"></div> -->
      </div>
      <div class="partners-payment">
        <div class="partners-payment-content">
          <div class="title Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">
            {{ $t('home.text_5') }}
          </div>
          <div class="desc Inter-Medium" :class="{ 'Alibaba-Medium': isZh }">
            {{ $t('home.text_6') }}
          </div>
          <div class="down-img-wrap">
            <img class="down" :src="downImg" @click="scrollIntoView" alt="" />
          </div>
          <div class="big-card-list">
            <Card
              v-for="(card, index) in bigCardList"
              :key="index"
              :color="card.color"
            >
              <div class="title Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">
                {{ card.title }}
              </div>
              <div class="child-list">
                <div
                  class="child-item"
                  v-for="(child, cIndex) in card.child"
                  :key="cIndex"
                >
                  <img :src="child.logo" alt="" />
                </div>
              </div>
            </Card>
          </div>
          <div class="small-card-list">
            <Card
              v-for="(card, index) in smallCardList"
              :key="index"
              :color="card.color"
            >
              <div class="title Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">
                {{ card.title }}
              </div>
              <img :src="card.img" alt="" />
            </Card>
          </div>
        </div>
      </div>
      <div class="product-features">
        <div class="product-features-content">
          <div class="title Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">
            {{ $t('home.text_7') }}
          </div>
          <div class="desc Inter-Medium" :class="{ 'Alibaba-Medium': isZh }">
            {{ $t('home.text_8') }}
          </div>
          <div class="features-info">
            <img class="features-img" :src="mainImg2" alt="" />
            <div class="features-list">
              <div
                class="features-item"
                v-for="(item, index) in featuresList"
                :key="index"
              >
                <div class="title-wrap">
                  <img :src="item.icon" alt="" />
                  <span class="Inter-Bold" :class="{ 'Alibaba-Bold': isZh }">{{
                    item.title
                  }}</span>
                </div>
                <div
                  class="detail Inter-Regular"
                  :class="{ 'Alibaba-Regular': isZh }"
                >
                  {{ item.detail }}
                </div>
              </div>
            </div>
          </div>
          <div class="banner">
            <img class="banner-pc" :class="{ chinese: isZh }" src="../assets/home/journey-pc.png" alt="" />
            <img
              class="banner-mobile"
              src="../assets/home/journey-mobile.png"
              alt=""
            />
            <div
              class="banner-title Inter-Bold"
              :class="{ 'Alibaba-Bold': isZh }"
            >
              {{ $t('home.text_15') }}
            </div>
            <div
              class="banner-desc Inter-Medium"
              :class="{ 'Alibaba-Medium': isZh }"
            >
              {{ $t('home.text_16') }}
            </div>
            <div
              class="button Inter-Bold"
              :class="{ 'Alibaba-Bold': isZh }"
              @click="goRamp"
              >{{ $t('startNow') }}</div
            >
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
const router = useRouter()

import { useCommonStore } from '../stores/common';
const common = useCommonStore();

import { setMode } from '../utils';
import { TP_SERVER_DOMAIN } from '../config';

import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';
import Card from '@/components/tools/Card.vue';

const { locale, t } = useI18n();
const state = reactive({
  fileList: [],
  curChain: {},
  loadingInstance: {},
  classOptions: {
    limitMoveNum: 8,
    direction: 2,
    hoverStop: false,
    isSingleRemUnit: true,
  },
});

const partnersNode = ref('');
const scrollInterval = ref('');
const offset = ref(0);

onMounted(() => {
  if (partnersNode.value) {
    // console.log('partnersNode', partnersNode);
    let speed = 0.5;
    scrollInterval.value = setInterval(() => {
      if (common.isMobileSize) {
        speed = 0.3;
      }
      offset.value -= speed;
      if (Math.abs(offset.value) >= partnersNode.value.clientWidth / 2) {
        offset.value = 0;
      }
    }, 10);
  }
});

onUnmounted(() => {
  clearInterval(scrollInterval.value);
});

const darkModeImg = computed(() => {
  return common.isDark ? '-dark' : '';
});

const isZh = computed(() => {
  return locale.value === 'zh';
});

const mainImgPc = computed(() => {
  return require(`../assets/home/main-img-pc${darkModeImg.value}.png`);
});
const mainImg = computed(() => {
  return require(`../assets/home/main-img${darkModeImg.value}.png`);
});
const mainImg2 = computed(() => {
  return require(`../assets/home/main-img-2${darkModeImg.value}.png`);
});
const downImg = computed(() => {
  return require(`../assets/home/down${darkModeImg.value}.png`);
});

const introductionList = computed(() => {
  return [
    {
      icon: require(`../assets/home/Cryptocurrencies${darkModeImg.value}.png`),
      text_1: '100+',
      text_2: t('home.text_2'),
    },
    {
      icon: require(`../assets/home/Countries${darkModeImg.value}.png`),
      text_1: '150+',
      text_2: t('home.text_3'),
    },
  ];
});

const partnerList = computed(() => {
  const list = [
    {
      logo: require(`../assets/home/partners/1${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/2${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/3${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/4${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/5${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/6${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/7${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/8${darkModeImg.value}.png`),
    },
  ];
  // return [...list, ...list];
  return list;
});
const partnerList2 = computed(() => {
  const list = [
    {
      logo: require(`../assets/home/partners/1${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/2${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/3${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/4${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/5${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/6${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/7${darkModeImg.value}.png`),
    },
    {
      logo: require(`../assets/home/partners/8${darkModeImg.value}.png`),
    },
  ];
  // return [...list, ...list];
  return [...list, ...list];
});

const bigCardList = computed(() => {
  let color1 = {
    bgColor: '#2980FE',
    borderColor: '#69A6FF',
    cardLineBgColor: '#1F73ED',
  };
  let color1_dark = {
    bgColor: '#0F3770',
    borderColor: '#215298',
    cardLineBgColor: '#0A2956',
  };
  let color2 = {
    bgColor: '#26a17b',
    borderColor: '#4ad7ac',
    cardLineBgColor: '#219370',
  };
  let color2_dark = {
    bgColor: '#106F51',
    borderColor: '#178764',
    cardLineBgColor: '#0C5C43',
  };
  return [
    {
      title: t('home.card_text_1'),
      color: common.isDark ? color1_dark : color1,
      child: [
        {
          logo: require(`../assets/home/payment-1.png`),
        },
        {
          logo: require(`../assets/home/payment-2.png`),
        },
        {
          logo: require(`../assets/home/payment-3.png`),
        },
        {
          logo: require(`../assets/home/payment-4.png`),
        },
        {
          logo: require(`../assets/home/payment-5.png`),
        },
        {
          logo: require(`../assets/home/payment-6.png`),
        },
        {
          logo: require(`../assets/home/payment-7.png`),
        },
      ],
    },
    {
      title: t('home.card_text_2'),
      color: common.isDark ? color2_dark : color2,
      child: [
        {
          logo: require(`../assets/home/partners-1.png`),
        },
        {
          logo: require(`../assets/home/partners-2.png`),
        },
        {
          logo: require(`../assets/home/partners-3.png`),
        },
        {
          logo: require(`../assets/home/partners-4.png`),
        },
        {
          logo: require(`../assets/home/partners-5.png`),
        },
        {
          logo: require(`../assets/home/partners-6.png`),
        },
        {
          logo: require(`../assets/home/partners-7.png`),
        },
        {
          logo: require(`../assets/home/partners-8.png`),
        },
        {
          logo: require(`../assets/home/partners-9.png`),
        },
      ],
    },
  ];
});
const smallCardList = computed(() => {
  let color = {
    bgColor: '#F5F5F5',
    borderColor: '#EEEEEE',
    cardLineBgColor: '#E2E2E2',
  };
  let color_dark = {
    bgColor: '#0D0E0F',
    borderColor: '#232527',
    cardLineBgColor: '#232527',
  };
  return [
    {
      title: t('home.card_text_3'),
      img: require(`../assets/home/card-1${darkModeImg.value}.png`),
      color: common.isDark ? color_dark : color,
    },
    {
      title: t('home.card_text_4'),
      img: require(`../assets/home/card-2${darkModeImg.value}.png`),
      color: common.isDark ? color_dark : color,
    },
    {
      title: t('home.card_text_5'),
      img: require(`../assets/home/card-3${darkModeImg.value}.png`),
      color: common.isDark ? color_dark : color,
    },
  ];
});

const featuresList = computed(() => {
  return [
    {
      icon: require(`../assets/home/Best-prices${darkModeImg.value}.png`),
      title: t('home.text_9'),
      detail: t('home.text_10'),
    },
    {
      icon: require(`../assets/home/protection${darkModeImg.value}.png`),
      title: t('home.text_11'),
      detail: t('home.text_12'),
    },
    {
      icon: require(`../assets/home/Truly-owned${darkModeImg.value}.png`),
      title: t('home.text_13'),
      detail: t('home.text_14'),
    },
  ];
});

const goRamp = () => {
  router.push('/ramp')
}

const scrollIntoView = () => {
  const node = document.querySelector('.big-card-list');
  if (node) {
    node.scrollIntoView({
      behavior: 'smooth', // 平滑过渡
      block: 'center', // 居中
    });
  }
};

const changLang = () => {
  if (locale.value === 'zh') {
    locale.value = 'en';
  } else {
    locale.value = 'zh';
  }
};

function changeMode(payload) {
  common.changeMode(true);
}
</script>

<style lang="scss" scoped>
.home {
  background: var(--bg);
  @mixin button {
    font-size: 0.28rem;
    color: #c0c3c6;
    width: fit-content;
    background: #2980fe;
    border-radius: 0.44rem;
    font-size: 0.32rem;
    color: #ffffff;
    text-align: center;
    padding: 0.24rem 0.52rem;
    cursor: pointer;
  }
  .home-container {
    margin-top: 0.5rem;
    .introduction-content {
      .introduction {
        position: relative;
        max-width: 12rem;
        margin: 0 auto;
        padding-bottom: 2rem;
        .title-wrap {
          position: relative;
          z-index: 10;
          max-width: 6rem;
          .title {
            font-size: 0.56rem;
            color: var(--color);
            line-height: 0.76rem;
          }
        }
        .button {
          @include button();
          margin-top: 0.48rem;
        }
        .introduction-list {
          position: relative;
          z-index: 10;
          margin-top: 0.9rem;
          display: flex;
          align-items: center;
          .introduction-item {
            display: flex;
            align-items: center;
            &:nth-child(n + 2) {
              margin-left: 0.6rem;
            }
            img {
              width: 0.32rem;
              height: 0.32rem;
            }
            .text-wrap {
              margin-left: 0.12rem;
              display: flex;
              align-items: center;
              :nth-child(1) {
                font-size: 0.22rem;
                color: var(--color);
              }
              :nth-child(2) {
                margin-left: 0.08rem;
                font-size: 0.13rem;
                color: var(--color);
              }
            }
          }
        }
        .main-img-pc {
          position: absolute;
          right: 0;
          top: 0;
          width: 9.81rem;
          height: 6.28rem;
          pointer-events: none;
        }
        .main-img-mobile {
          display: none;
        }
      }
      .partners-content {
        background: var(--bg-2);
        padding: 0.32rem 0 0.66rem;
        overflow: hidden;
        .partners-wrap {
          position: relative;
          max-width: 12rem;
          margin: 0 auto;
          overflow: hidden;
          .title {
            font-size: 0.18rem;
            color: var(--color-2);
          }
          .scroll-seamless {
            width: 100%;
            margin-top: 0.25rem;
            overflow: hidden;
          }
          .partners-list {
            margin-top: 0.25rem;
            display: flex;
            width: fit-content;
            .partners-item {
              width: fit-content;
              background: var(--bg-7);
              border-radius: 0.16rem;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.1rem 0.2rem;
              margin-left: 0.1rem;
              img {
                // width: 1.28rem;
                height: 0.4rem;
              }
            }
          }
          .shadow {
            position: absolute;
            z-index: 10;
            top: 50%;
            transform: translateY(-30%);
            right: -3.5rem;
            width: 3.6rem;
            height: 0.93rem;
            background: var(--bg-8);
            box-shadow: -0.4rem 0 0.3rem 0 var(--bg-8);
          }
        }
      }
      .bg-over {
        height: 0.9rem;
        background: linear-gradient(180deg, var(--bg), var(--bg-6));
      }
    }
    .partners-payment {
      background: var(--bg-6);
      padding-top: 1.88rem;
      .partners-payment-content {
        max-width: 12rem;
        margin: 0 auto;
        .title {
          font-size: 0.44rem;
          text-align: center;
          color: var(--color);
          line-height: 0.72rem;
        }
        .desc {
          margin-top: 0.18rem;
          font-size: 0.22rem;
          text-align: center;
          color: var(--color);
        }
        .down-img-wrap {
          margin-top: 0.6rem;
          display: flex;
          justify-content: center;
          img {
            width: 0.88rem;
            height: 0.88rem;
            cursor: pointer;
          }
        }
        .big-card-list {
          margin-top: 1.88rem;
          display: flex;
          justify-content: space-between;
          .card {
            max-width: 5.9rem;
            &:nth-child(2) {
              .child-list {
                .child-item {
                  background: var(--bg-4);
                  border: 0.01rem solid var(--border-4);
                  &:nth-child(7) {
                    border-radius: 0.04rem 0.04rem 0.04rem 0.2rem;
                  }
                  &:nth-child(9) {
                    border-radius: 0.04rem 0.04rem 0.2rem 0.04rem;
                  }
                }
              }
            }
            .title {
              margin-top: 0.3rem;
              font-size: 0.32rem;
              text-align: center;
              color: var(--color-1);
              line-height: 0.44rem;
            }
            .child-list {
              margin: 0.37rem 0 0.54rem;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              .child-item {
                background: var(--bg-3);
                border: 0.01rem solid var(--border-3);
                border-radius: 0.04rem;
                padding: 0.15rem;
                font-size: 0;
                &:nth-child(n + 2) {
                  margin-left: 0.02rem;
                }
                &:nth-child(n + 4) {
                  margin-top: 0.02rem;
                }
                &:nth-child(1) {
                  border-radius: 0.2rem 0.04rem 0.04rem 0.04rem;
                }
                &:nth-child(3) {
                  border-radius: 0.04rem 0.2rem 0.04rem 0.04rem;
                }
                img {
                  width: 1.28rem;
                  height: 1.28rem;
                }
              }
            }
          }
        }
        .small-card-list {
          margin-top: 1.28rem;
          display: flex;
          justify-content: space-between;
          .card {
            max-width: 3.87rem;
            border-radius: 0.44rem;
            font-size: 0;
            .title {
              margin-top: 0.36rem;
              font-size: 0.24rem;
              text-align: center;
              color: var(--color);
              padding: 0 0.48rem;
              line-height: 0.29rem;
            }
            img {
              width: 3.87rem;
            }
          }
        }
      }
    }
    .product-features {
      background: var(--bg-6);
      padding: 1.88rem 0 1.28rem;
      .product-features-content {
        max-width: 12rem;
        margin: 0 auto;
        .title {
          font-size: 0.44rem;
          text-align: center;
          color: var(--color);
        }
        .desc {
          margin-top: 0.2rem;
          font-size: 0.2rem;
          text-align: center;
          color: var(--color);
          line-height: 0.24rem;
        }
        .features-info {
          margin-top: 0.72rem;
          display: flex;
          .features-img {
            width: 4.99rem;
            height: 5.87rem;
          }
          .features-list {
            margin-left: 0.35rem;
            margin-top: 0.1rem;
            .features-item {
              padding: 0.24rem;
              background: var(--bg-5);
              border: 0.02rem solid var(--border-5);
              border-radius: 0.28rem;
              &:nth-child(n + 2) {
                margin-top: 0.12rem;
              }
              .title-wrap {
                display: flex;
                align-items: center;
                img {
                  width: 0.4rem;
                  height: 0.4rem;
                }
                span {
                  margin-left: 0.2rem;
                  font-size: 0.28rem;
                  text-align: left;
                  color: var(--color);
                }
              }
              .detail {
                margin: 0.08rem 0 0 0.6rem;
                font-size: 0.18rem;
                color: var(--color);
              }
            }
          }
        }
        .banner {
          position: relative;
          margin-top: 1.5rem;
          background: #2980fe;
          border-radius: 0.24rem;
          padding: 0.76rem 0 0.61rem 0.66rem;
          .banner-pc {
            height: 3.47rem;
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            &.chinese {
              height: 3.62rem;
            }
          }
          .banner-mobile {
            display: none;
          }
          .banner-title {
            font-size: 0.4rem;
            color: #ffffff;
          }
          .banner-desc {
            position: relative;
            z-index: 10;
            margin-top: 0.28rem;
            font-size: 0.22rem;
            color: #ffffff;
          }
          .button {
            @include button();
            margin-top: 0.28rem;
            background: #2980fe;
            border: 0.02rem solid #ffffff;
            padding: 0.18rem 0.52rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .home {
    .home-container {
      .introduction-content {
        .introduction {
          padding: 0 0.3rem;
        }
        .partners-content {
          padding: 1.5rem 0.3rem 0.66rem;
        }
      }
      .partners-payment {
        padding: 1.88rem 0.3rem 0;
        .partners-payment-content {
          .big-card-list {
            .card {
              max-width: none;
              width: 58%;
              &:nth-child(n + 2) {
                margin-left: 0.2rem;
              }
            }
          }
          .small-card-list {
            .card {
              max-width: none;
              width: 31%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
      .product-features {
        .product-features-content {
          padding: 0 0.3rem;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .home {
    .home-container {
      margin-top: 0.2rem;
      .introduction-content {
        .introduction {
          padding: 0;
          .title-wrap {
            max-width: none;
            padding: 0 0.5rem;
            text-align: center;
            line-height: 1rem;
            .title {
              font-size: 0.56rem;
              text-align: center;
              line-height: normal;
              display: inline-block;
            }
          }
          .button {
            margin: 0.5rem auto 0;
            display: block;
          }
          .introduction-list {
            margin: 0.6rem 1.12rem 0;
            justify-content: space-between;
            &.chinese {
              justify-content: space-around;
            }
            .introduction-item {
              img {
                width: 0.44rem;
                height: 0.44rem;
              }
              .text-wrap {
                :nth-child(1) {
                  font-size: 0.28rem;
                }
                :nth-child(2) {
                  font-size: 0.18rem;
                }
              }
            }
          }
          .main-img-pc {
            display: none;
          }
          .main-img-mobile {
            display: block;
            width: 100%;
            margin-top: 0.64rem;
          }
        }
        .partners-content {
          padding: 0.32rem 0;
          .partners-wrap {
            margin: 0 0.36rem;
            .title {
              font-size: 0.26rem;
            }
            .partners-list {
              .partners-item {
                img {
                  height: 0.5rem;
                }
              }
            }
            .shadow {
              width: 0.3rem;
              right: 0;
              box-shadow: -0.2rem 0 0.2rem 0 var(--bg-8);
            }
          }
        }
      }
      .partners-payment {
        padding: 1.88rem 0 0;
        .partners-payment-content {
          .title {
            padding: 0 0.36rem;
            font-size: 0.56rem;
          }
          .desc {
            margin-top: 0.42rem;
            padding: 0 0.36rem;
            font-size: 0.32rem;
          }
          .down-img-wrap {
            display: none;
          }
          .big-card-list {
            flex-direction: column;
            width: calc(100% - 0.72rem);
            margin: 0.98rem auto 0;
            .card {
              width: auto;
              border-radius: 0.64rem;
              &:nth-child(n + 2) {
                margin-top: 0.36rem;
                margin-left: 0;
              }
              .title {
                margin-top: 0.46rem;
                padding: 0 0.5rem;
                font-size: 0.42rem;
              }
              .child-list {
                margin: 0.88rem 0.6rem 0.6rem;
                .child-item {
                  padding: 0.25rem;
                }
              }
            }
          }
          .small-card-list {
            overflow: auto;
            padding: 0 0.36rem;
            &::-webkit-scrollbar {
              display: none;
            }
            scrollbar-width: none;
            .card {
              width: 5.8rem;
              max-width: none;
              border-radius: 0.66rem;
              &:nth-child(n + 2) {
                margin-left: 0.3rem;
              }
              .title {
                margin-top: 0.54rem;
                font-size: 0.36rem;
                line-height: 0.44rem;
                padding: 0 0.7rem;
              }
              img {
                margin-top: 0.7rem;
                width: 5.8rem;
              }
            }
          }
        }
      }
      .product-features {
        .product-features-content {
          padding: 0;
          .title {
            padding: 0 0.36rem;
            font-size: 0.56rem;
          }
          .desc {
            margin-top: 0.42rem;
            padding: 0 0.36rem;
            font-size: 0.32rem;
            line-height: 0.39rem;
          }
          .features-info {
            margin-top: 0.8rem;
            flex-direction: column;
            align-items: center;
            .features-img {
              width: 80%;
              height: auto;
            }
            .features-list {
              margin: 0.6rem 0.36rem 0;
              .features-item {
                &:nth-child(n + 2) {
                  margin-top: 0.2rem;
                }
                .title-wrap {
                  img {
                    width: 0.48rem;
                    height: 0.48rem;
                  }
                  span {
                    margin-left: 0.2rem;
                    font-size: 0.32rem;
                  }
                }
                .detail {
                  margin: 0.12rem 0 0 0.68rem;
                  font-size: 0.22rem;
                }
              }
            }
          }
          .banner {
            height: 5.88rem;
            margin: 0.88rem 0.36rem 0;
            border-radius: 0.66rem;
            padding: 0.56rem 0.46rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .banner-pc {
              display: none;
            }
            .banner-mobile {
              display: block;
              width: 100%;
              position: absolute;
              z-index: 1;
              bottom: 0;
            }
            .banner-title {
              font-size: 0.36rem;
            }
            .banner-desc {
              margin-top: 0.32rem;
              font-size: 0.2rem;
            }
            .button {
              position: relative;
              z-index: 10;
              font-size: 0.28rem;
              margin-top: 0.4rem;
              padding: 0.19rem 0.52rem;
            }
          }
        }
      }
    }
  }
}
</style>
