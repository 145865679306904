<template>
  <router-view />
  <el-drawer
    v-model="common.isAppSettingDialog"
    direction="btt"
    :show-close="false"
    :with-header="false"
    style="height: auto"
  >
    <AppSettingsPanel />
  </el-drawer>
  <el-dialog
    v-model="dialogVisible"
    align-center
    modal-class="contact-us-modal"
    :show-close="false"
  >
    <div class="modal-content">
      <div class="title">{{ $t('home.modal_contact_us.title') }}</div>
      <div class="desc">
        {{ $t('home.modal_contact_us.text_1') }}
      </div>
      <ContactUsList />
      <div class="contact-transit-buy">
        <div class="title">{{ $t('home.modal_contact_us.text_2') }}</div>
        <a href="https://openc.zendesk.com/hc/en-us/requests/new" target="_blank" class="button">
          <div class="contact-img" :class="{ isDark: common.isDark }"></div>
        </a>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import tp from "tp-js-sdk";
import 'element-plus/dist/index.css';
import { ElDialog, ElDrawer } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { useCommonStore } from './stores/common';
const common = useCommonStore();

import emitter from './utils/bus';
import { setMode } from './utils';
import ContactUsList from './components/tools/ContactUsList.vue';
import AppSettingsPanel from './components/tools/AppSettingsPanel.vue';

const { t } = useI18n();

const dialogVisible = ref(false);

let theme = localStorage.getItem('theme');
let mode = false;

// 如果已经设置过暗黑模式
const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)'); // 是深色
// console.log('isDarkTheme', isDarkTheme);
if (theme) {
  if (theme === 'auto' && isDarkTheme) {
    if (isDarkTheme.matches) {
      // 主题设置为深色。
      mode = true;
    } else {
      // 主题设置为浅色。
      mode = false;
    }
  } else if (theme == 'dark') {
    mode = true;
  } else {
    mode = false;
  }
  setMode(mode)
  common.changeMode(mode)
  // store.commit('setDarkMode', mode)
} else {
  if (navigator.userAgent.indexOf('TokenPocket') === -1) {
    if (isDarkTheme.matches) {
      // 主题设置为深色。
      mode = true;
    } else {
      // 主题设置为浅色。
      mode = false;
    }
    setMode(mode)
    common.changeMode(mode)
    // store.commit('setDarkMode', mode)
  } else {
    tp.isDarkMode().then(res => {
      // console.log('tp', res);
      if (res && res.data) {
        mode = true
      } else {
        mode = false
      }
      setMode(mode)
      common.changeMode(mode)
      // store.commit('setDarkMode', mode)
    })
  }
}

onMounted(() => {
  // document.title = t('feedbackTitle');
  systemChange();
  common.isMobileSize = document.documentElement.clientWidth <= 767;
  window.onresize = () => {
    common.isMobileSize = document.documentElement.clientWidth <= 767;
  };
  emitter.on('contact-us', (res) => {
    // console.log('res', res);
    if (res && res.show) {
      dialogVisible.value = true;
    }
  });
});

const systemChange = () => {
  const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  if (isDarkTheme) {
    isDarkTheme.onchange = (e) => {
      // console.log('systemChange', isDarkTheme);
      const theme = localStorage.getItem('theme');
      let mode = false;
      if (theme && theme === 'auto') {
        console.log('auto');
        if (e.matches) {
          console.log('设置深色主题');
          mode = true;
        } else {
          console.log('设置浅色主题');
          mode = false;
        }
        common.changeMode(mode);
        setMode(mode);
      }
    };
  }
};
</script>

<style lang="scss">
.el-drawer {
  border-radius: 24px 24px 0 0;
  background: var(--wrap-bg);
  height: auto !important;
  .el-drawer__body {
    padding: 16px 16px 61px;
  }
}
.el-overlay {
  .el-dialog {
    padding: 32px 32px;
    background: var(--wrap-bg);
    border: 1px solid var(--mobile-app-setting-border);
    border-radius: 24px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    width: 458px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .modal-content {
      .title {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: var(--color);
        line-height: 28px;
      }
      .desc {
        margin-top: 16px;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #e55036;
        line-height: 21px;
      }
      .contact-transit-buy {
        margin-top: 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .button {
          margin-top: 0.16rem;
          width: 0.64rem;
          height: 0.64rem;
          border: 0.01rem solid var(--border-1);
          border-radius: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .contact-img {
            width: 0.32rem;
            height: 0.3rem;
            background: url('./assets/home/contact.png') no-repeat 100% / contain;
            &.isDark {
              background: url('./assets/home/contact-dark.png') no-repeat 100% / contain;
            }
          }
        }
        .button:hover {
          background: #2980fe;
          border: 1px solid #2980fe;
          .contact-img {
            width: 0.32rem;
            height: 0.3rem;
            background: url('./assets/home/contact-dark.png') no-repeat 100% / contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .el-overlay {
    .el-dialog {
      width: auto;
      max-width: 80%;
    }
  }
}
</style>
