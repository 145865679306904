let TP_SERVER_DOMAIN, FORWARD_SERVER_DOMAIN, EXPLORER_TRANSIT_DOMAIN;

if (process.env.VUE_APP_ENV === 'production') {
  TP_SERVER_DOMAIN = 'https://preserver.mytokenpocket.vip';
  FORWARD_SERVER_DOMAIN = 'https://forward.mytokenpocket.vip';
  EXPLORER_TRANSIT_DOMAIN = 'https://explorer.transit.finance/#/hash/';
} else if (process.env.VUE_APP_ENV === 'beta') {
  TP_SERVER_DOMAIN = 'https://betaserver.mytokenpocket.vip';
  FORWARD_SERVER_DOMAIN = 'https://forward.mytokenpocket.vip';
  EXPLORER_TRANSIT_DOMAIN =
  'https://test-tp.tokenpocket.pro/swap-explorer/#/hash/';
} else if (process.env.VUE_APP_ENV === 'dev') {
  TP_SERVER_DOMAIN = 'https://testserver.mytokenpocket.vip';
  FORWARD_SERVER_DOMAIN = 'https://forwardtest.mytokenpocket.vip';
  EXPLORER_TRANSIT_DOMAIN =
    'https://test-tp.tokenpocket.pro/swap-explorer/#/hash/';
}

export { TP_SERVER_DOMAIN, FORWARD_SERVER_DOMAIN, EXPLORER_TRANSIT_DOMAIN };
