<template>
  <div class="contact-us-list">
    <a
      class="follow-item"
      :class="[item.class]"
      target="_blank"
      :href="item.url"
      v-for="(item, index) in contactUsList"
      :key="index"
    >
      <div class="footer-follow"></div>
    </a>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useCommonStore } from '../../stores/common';
const common = useCommonStore();

const darkModeImg = computed(() => {
  return common.isDark ? '-dark' : '';
});

const contactUsList = computed(() => {
  return [
    {
      url: 'https://twitter.com/TransitFinance',
      class: `follow-item-1${darkModeImg.value}`,
    },
    {
      url: 'https://t.me/Transit_Finance',
      class: `follow-item-2${darkModeImg.value}`,
    },
    {
      url: 'mailto:service@transit.finance',
      class: `follow-item-3${darkModeImg.value}`,
    },
  ];
})
</script>

<style lang="scss" scoped>
.contact-us-list {
  display: flex;
  justify-content: center;
  margin: 32px 0 0;
  @for $i from 1 through 3 {
    .follow-item-#{$i} {
      .footer-follow {
        background: url('../../assets/footer/#{$i}.png')
          no-repeat
          100% /
          contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 1px solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/#{$i}-on.png')
            no-repeat
            100% /
            contain;
        }
      }
    }
    .follow-item-#{$i}-dark {
      .footer-follow {
        background: url('../../assets/footer/#{$i}-dark.png')
          no-repeat
          100% /
          contain;
      }
      cursor: pointer;
      &:hover {
        background: #2980fe;
        border: 1px solid #2980fe;
        .footer-follow {
          background: url('../../assets/footer/#{$i}-on.png')
            no-repeat
            100% /
            contain;
        }
      }
    }
  }

  // .loop1(3);
  .follow-item {
    width: 62px;
    height: 62px;
    border: 1px solid var(--border-1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(n + 2) {
      margin-left: 28px;
    }
    .footer-follow {
      width: 32px;
      height: 32px;
    }
  }
}
@media screen and (max-width: 767px) {
  .contact-us-list {
    margin: 22px 0 0;
    @for $i from 1 through 3 {
      .follow-item-#{i} {
        .footer-follow {
          background: url('../../assets/footer/#{$i}.png')
            no-repeat
            100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          border: 1px solid var(--border-1);
          .footer-follow {
            background: url('../../assets/footer/#{$i}.png')
              no-repeat
              100% /
              contain;
          }
        }
      }
      .follow-item-#{$i}-dark {
        .footer-follow {
          background: url('../../assets/footer/#{$i}-dark.png')
            no-repeat
            100% /
            contain;
        }
        cursor: pointer;
        &:hover {
          background: none;
          border: 1px solid var(--border-1);
          .footer-follow {
            background: url('../../assets/footer/#{$i}-dark.png')
              no-repeat
              100% /
              contain;
          }
        }
      }
    }

    .follow-item {
      border-radius: 12px;
      width: 44px;
      height: 44px;
      .footer-follow {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
