<template>
  <div class="header">
    <div class="header-left">
      <img
        :src="logoImg"
        alt="Transit Markets"
        class="logo markets-logo"
        @click="goHome"
      />
      <ul class="nav-list">
        <li>
          <a
            @click="goSwap"
            class="Inter-Medium"
            :class="{ 'Alibaba-Medium': isZh }"
          >
            {{ $t('swap') }}
          </a>
        </li>
        <li class="markets">
          <a
            @click="goMarkets"
            class="Inter-Medium"
            :class="{ 'Alibaba-Medium': isZh }"
          >
            {{ $t('header.markets') }}
          </a>
          <!-- <img src="../../assets/header/soon.png" alt="" /> -->
        </li>
        <li class="buy-token">
          <img :src="buyImg" alt="" />
          <a
            @click="$router.push('/ramp')"
            class="Inter-Medium"
            :class="{ 'Alibaba-Medium': isZh }"
            >{{ $t('header.buyToken') }}</a
          >
        </li>
        <!-- <li class="inscribe-tool">
          <a
            :href="inscribeToolUrl"
            target="_blank"
            class="Inter-Medium"
            :class="{ 'Alibaba-Medium': isZh }"
            >{{ $t('header.inscribeTool') }}</a
          >
        </li> -->
      </ul>
    </div>

    <div class="header-right">
      <!-- <img
        :src="
          require(`../../assets/header/search/swap-token-search${
            common.isDark ? '_dark' : ''
          }.png`)
        "
        class="mobile-search"
      /> -->
      <div class="menu-wrap">
        <img class="menu-img" :src="moreImg" @click.stop="onMenu" alt="" />
        <div
          class="app-settings-panel with-triangle"
          :class="{ 'app-setting-panel-ani': isAni }"
          v-if="appSettingsVisible"
        >
          <!-- v-if="appSettingsVisible" -->
          <AppSettingsPanel />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
import AppSettingsPanel from '../tools/AppSettingsPanel.vue';

import { useCommonStore } from '../../stores/common';
const common = useCommonStore();

const appSettingsVisible = ref(false);
const isAni = ref(false);

const darkModeImg = computed(() => {
  return common.isDark ? '-dark' : '';
});

const isZh = computed(() => {
  return locale.value === 'zh';
});

const buyImg = computed(() => {
  return require(`../../assets/header/buy${darkModeImg.value}.png`);
});

const logoImg = computed(() => {
  return require(`../../assets/home/Transit${darkModeImg.value}.png`);
});

const moreImg = computed(() => {
  // return require(`../../assets/header/more${darkModeImg.value}.png`);
  return require(`../../assets/header/more.png`);
});

const inscribeToolUrl = computed(() => {
  return `https://tp-lab.tokenpocket.pro/insc/?locale=${locale.value}#/`;
});

watch(
  appSettingsVisible,
  (n, o) => {
    // console.log('watch 已触发', n);
    if (n) {
      setTimeout(() => {
        isAni.value = true;
      }, 0);
    } else {
      isAni.value = false;
    }
  },
  { immediate: false }
);

onMounted(() => {
  document.body.addEventListener('click', () => {
    appSettingsVisible.value = false;
  });
});

const goHome = () => {
  window.open(`https://www.transit.finance/?locale=${locale.value}#/`, '_blank')
}

const onMenu = () => {
  if (common.isMobileSize) {
    common.changeAppSettingDialog(true);
  } else {
    appSettingsVisible.value = !appSettingsVisible.value;
  }
};

const goMarkets = () => {
  // console.log('locale', locale);
  window.open(`https://swap.transit.finance/?locale=${locale.value}#/market`);
};
const goSwap = () => {
  window.open(`https://swap.transit.finance/?locale=${locale.value}#/`);
};
</script>

<style lang="scss">
.header {
  max-width: 13.6rem;
  margin: 0 auto;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-left {
    display: flex;
    align-items: center;
    flex: 1;
    .logo {
      height: 0.24rem;
      margin: 0.04rem 0 0.06rem 0.04rem;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
    .nav-list {
      position: relative;
      top: 0.01rem;
      padding: 0;
      line-height: 0.32rem;
      display: flex;
      margin: 0 0.08rem 0 0.2rem;

      li {
        padding: 0;
        display: flex;
        align-items: center;
        margin: 0 0.08rem;
        a {
          text-decoration: none;
          color: var(--color);
          font-size: 0.15rem;
          cursor: pointer;
        }
        img {
          margin-right: 0.04rem;
          width: 0.18rem;
          height: 0.18rem;
        }
      }
    }
  }
  .header-right {
    display: flex;
    .mobile-search {
      display: none;
    }
    .menu-wrap {
      position: relative;
      display: flex;
      .menu-img {
        width: 0.36rem;
        height: 0.36rem;
        cursor: pointer;
      }
      .app-settings-panel {
        position: absolute;
        top: 0.45rem;
        right: 0;
        min-width: 1.68rem;
        background: var(--wrap-bg);
        box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.2);
        padding: 0.12rem 0.12rem 0.16rem;
        z-index: 9;
        border-radius: 0.12rem;
        opacity: 0;
        transform: scale(0);
        transform-origin: right top;
        transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s,
          transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
        &.app-setting-panel-ani {
          opacity: 1;
          transform: scale(1);
        }
        &::after {
          right: 0.1rem;
        }
      }
    }
  }
  .nav-list-m {
    .optional-wrap {
      height: 0.28rem;
      display: inline-block;
      .optional-img {
        width: 0.24rem;
        height: 0.24rem;
        vertical-align: middle;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .header {
    margin: 0 0.3rem;
  }
}
@media screen and (max-width: 767px) {
  .header {
    margin: 0;
    height: 1rem;
    padding: 0 0.32rem;
    .header-left {
      .nav-list {
        display: none;
      }
      .logo {
        height: 0.56rem;
      }
    }
    .header-right {
      align-items: center;
      .mobile-search {
        display: block;
        margin-right: 0.16rem;
        width: 0.6rem;
        height: 0.6rem;
      }
      .menu-wrap {
        .menu-img {
          width: 0.56rem;
          height: 0.56rem;
        }
      }
    }
  }
}
</style>
